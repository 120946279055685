import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const CashFlow = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 145 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="72.5" cy="72.8601" r="69.5" fill="white" stroke="#C4C4C4" strokeWidth="5"/>
      <path d="M105.008 12.6482C78.7569 -1.23238 45.4846 2.87523 23.3888 24.9711C1.94721 46.4126 -2.55564 78.3786 9.88023 104.241M135.185 43.9894C147.621 69.8514 143.118 101.817 121.677 123.259C100.235 144.7 68.2691 149.203 42.4071 136.767" stroke="#333333" strokeWidth="5" strokeMiterlimit="16" strokeDasharray="5 5"/>
      <circle cx="72.75" cy="73.1101" r="32.25" fill="#EDF7ED" stroke="#47B347" strokeWidth="5"/>
      <path d="M70.6634 85.5473C70.8278 84.2658 70.9264 82.9187 70.9264 81.3087V79.0744H65.9632C64.4841 79.0744 63.6295 79.1401 62.512 79.3372V75.5915C63.6624 75.7887 64.4512 75.8544 65.9632 75.8544H70.9264V74.8687L69.8417 73.193H66.0289C64.517 73.193 63.6952 73.2587 62.5777 73.4558V69.7101C63.6624 69.9073 64.517 69.973 66.0289 69.973H67.8696L63.3008 62.7444C62.3476 61.2658 61.493 59.9844 61 59.3601H66.6534C66.9493 60.313 67.3108 61.0358 68.2311 62.5801L73.0957 70.9258L77.9603 62.613C78.8807 61.0687 79.2751 60.2473 79.538 59.3601H85.2243C84.7971 59.9187 83.4823 61.7915 82.8907 62.7444L78.3548 69.973H80.1954C81.6745 69.973 82.562 69.9073 83.6466 69.7101V73.4558C82.5291 73.2587 81.6416 73.193 80.1954 73.193H76.3498L75.2651 74.9015V75.8544H80.1297C81.6088 75.8544 82.4305 75.7887 83.5809 75.5915V79.3372C82.4634 79.1401 81.5759 79.0744 80.1297 79.0744H75.2651V81.3087C75.2651 83.1815 75.3308 84.233 75.528 85.5473H70.6634Z" fill="#47B247"/>
      <path d="M14.9648 114.535L1.00012 104.422L16.4639 97.3601L14.9648 114.535Z" fill="#333333"/>
      <path d="M130.793 31.7504L144.571 42.1159L128.981 48.895L130.793 31.7504Z" fill="#333333"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(145, 147)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default CashFlow
