import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { layouts } from '@/utils/preset'
import Typography from '@/components/common/Typography'
import SaveTime from '@/components/svg/SaveTime'
import Integrate from '@/components/svg/Integrate'
import Planning from '@/components/svg/Planning'
import CashFlow from '@/components/svg/CashFlow'
import SolutionCard from './SolutionCard'

const AboutSolutions = props => {
  return (
    <Root {...props}>
      <Head>
        <Title variant="h3" color="gray.e">このようなことで<br />悩んでいませんか？</Title>
      </Head>
      <Main>
        <CardList>
          <CardList_Container>
            <CardList_Item>
              <Card
                icon={(
                  <SaveTime />
                )}
                body={(
                  <Fragment>
                    定型業務を効率化し、生産的な仕事に専念したい。
                  </Fragment>
                )}
              />
            </CardList_Item>
            <CardList_Item>
              <Card
                icon={(
                  <Integrate />
                )}
                body={(
                  <Fragment>
                    データ管理を1つのシステムで集約・共有したい。
                  </Fragment>
                )}
              />
            </CardList_Item>
            <CardList_Item>
              <Card
                icon={(
                  <Planning />
                )}
                body={(
                  <Fragment>
                    販売予測から仕入計画を立て、最適な在庫管理をしたい。
                  </Fragment>
                )}
              />
            </CardList_Item>
            <CardList_Item>
              <Card
                icon={(
                  <CashFlow />
                )}
                body={(
                  <Fragment>
                    入出金計画から正確なキャッシュフローを把握したい。
                  </Fragment>
                )}
              />
            </CardList_Item>
          </CardList_Container>
        </CardList>
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Head = styled.div``

const Title = styled(Typography)`
  text-align: center;
  @media ${mq.and(desktop, laptop, tablet)} {
    br:nth-of-type(1) {
      display: none;
    }
  }
`

const Main = styled.div``

const CardList = styled.div`
  margin: 48px auto 0;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  @media ${mq.and(mobile)} {
    margin-top: 16px;
  }
`

const CardList_Container = styled.ul`
  display: block;
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media ${mq.and(mobile)} {
    margin: -24px -8px 0;
  }
`

const CardList_Item = styled.li`
  padding: 0 8px;
  @media ${mq.and(desktop, laptop, tablet)} {
    width: 25%;
  }
  @media ${mq.and(mobile)} {
    margin-top: 24px;
    width: 50%;
  }

  > * {
    height: 100%;
  }
`

const Card = styled(SolutionCard)`
`

export default ThemeConsumer(AboutSolutions)
