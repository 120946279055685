import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { Link } from 'gatsby'
import Headline from '@/components/app/Headline'
import Typography from '@/components/common/Typography'
import Bg from '@/components/app/Bg'
import Overview from '@/components/svg/Overview'
import Solutions from './Solutions'
import LinkButton1 from '@/components/common/LinkButton1'

const AboutLead = props => {
  const { theme } = props
  return (
    <Root {...props}>
      <Head>
        <Title theme={theme}>
          少人数で仕事を回す、<br />
          生産的な仕事に集中するには<br />
          <strong>ERPが不可欠</strong>
        </Title>
      </Head>
      <Main>
        <Body variant="body1">
          販売・購買・在庫・会計など基幹業務を1つのシステムで管理できるのがERP。会計データや在庫情報はリアルタイムに自動計算できるからこそ、部署の垣根を超えたシームレスなやり取りや、迅速かつ正確なマネジメントが可能になります。
        </Body>
        <Chart>
          <Chart_Content />
        </Chart>
        <SubSection>
          <Solutions />
        </SubSection>
      </Main>
      <Foot>
        <MoreButton to="/about/" component={Link} color="primary">FVPの特長を見る</MoreButton>
      </Foot>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
`

const Chart = styled(Bg)`
  margin: 64px auto 0;
  max-width: 996px;
  padding: 38px;
  @media ${mq.and(mobile)} {
    margin-top: 48px;
    margin-left: ${layouts.defs.pad.mobile * -1}px;
    margin-right: ${layouts.defs.pad.mobile * -1}px;
    padding-left: ${layouts.defs.pad.mobile}px;
    padding-right: ${layouts.defs.pad.mobile}px;
  }
`

const Chart_Content = styled(Overview)`
  width: 100%;
  max-width: 772px;
  margin: 0 auto;
`

const SubSection = styled.div`
  margin-top: 90px;
  @media ${mq.and(mobile)} {
    margin-top: 64px;
  }
`

const Foot = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`

const MoreButton = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

export default ThemeConsumer(AboutLead)
