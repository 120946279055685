import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const Planning = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 131 139" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="15.5" y="2.5" width="113" height="134" rx="2.4" fill="white" stroke="#828282" strokeWidth="5"/>
      <path d="M74 29.5C74 39.165 66.165 47 56.5 47C46.835 47 39 39.165 39 29.5C39 19.835 46.835 12 56.5 12C66.165 12 74 19.835 74 29.5Z" fill="#EDF7ED"/>
      <path d="M48 29.3333L53.78 35L65 24" stroke="#47B347" strokeWidth="5" strokeLinecap="round"/>
      <path d="M74 69.5C74 79.165 66.165 87 56.5 87C46.835 87 39 79.165 39 69.5C39 59.835 46.835 52 56.5 52C66.165 52 74 59.835 74 69.5Z" fill="#EDF7ED"/>
      <path d="M48 69.3333L53.78 75L65 64" stroke="#47B347" strokeWidth="5" strokeLinecap="round"/>
      <path d="M74 108.5C74 118.165 66.165 126 56.5 126C46.835 126 39 118.165 39 108.5C39 98.835 46.835 91 56.5 91C66.165 91 74 98.835 74 108.5Z" fill="#EDF7ED"/>
      <path d="M48 108.333L53.78 114L65 103" stroke="#47B347" strokeWidth="5" strokeLinecap="round"/>
      <rect x="2.5" y="24.5" width="25" height="9" rx="2.4" fill="white" stroke="#828282" strokeWidth="5"/>
      <rect x="2.5" y="64.5" width="25" height="9" rx="2.4" fill="white" stroke="#828282" strokeWidth="5"/>
      <rect x="2.5" y="104.5" width="25" height="9" rx="2.4" fill="white" stroke="#828282" strokeWidth="5"/>
      <line x1="85.5" y1="29.5" x2="113.5" y2="29.5" stroke="#333333" strokeWidth="5" strokeLinecap="round"/>
      <line x1="85.5" y1="68.5" x2="113.5" y2="68.5" stroke="#333333" strokeWidth="5" strokeLinecap="round"/>
      <line x1="85.5" y1="109.5" x2="113.5" y2="109.5" stroke="#333333" strokeWidth="5" strokeLinecap="round"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(131, 139)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default Planning
