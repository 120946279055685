import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const SaveTime = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 149 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H47.9289C48.592 2.5 49.2279 2.76339 49.6967 3.23223L78.7678 32.3033C79.2366 32.7721 79.5 33.408 79.5 34.0711V110C79.5 111.381 78.3807 112.5 77 112.5H5C3.61929 112.5 2.5 111.381 2.5 110V5Z" fill="white" stroke="#828282" strokeWidth="5"/>
      <path d="M50 0L82 32H50V0Z" fill="#333333"/>
      <path d="M146.5 92C146.5 117.129 126.129 137.5 101 137.5C75.871 137.5 55.5 117.129 55.5 92C55.5 66.871 75.871 46.5 101 46.5C126.129 46.5 146.5 66.871 146.5 92Z" fill="white" stroke="#47B347" strokeWidth="5"/>
      <path d="M116 92C116 100.284 109.284 107 101 107C92.7157 107 86 100.284 86 92C86 83.7157 92.7157 77 101 77C109.284 77 116 83.7157 116 92Z" fill="#EDF7ED"/>
      <path d="M101.5 63.5L101.5 89.5" stroke="#47B347" strokeWidth="5" strokeLinecap="round"/>
      <path d="M122.5 91.5L101.5 91.5" stroke="#47B347" strokeWidth="5" strokeLinecap="round"/>
      <rect x="99.5" y="89.5" width="4" height="4" fill="#47B347" stroke="#47B347"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(149, 140)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default SaveTime
