import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile, mobileL, mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import GatsbyImage from 'gatsby-image'
import { layouts } from '@/utils/preset'
import Typography from '@/components/common/Typography'
import LogoLarge from '@/components/app/LogoLarge'
import IconFont from '@/components/common/Icon'

const ConceptKV = props => {
  const { theme } = props
  return (
    <StaticQuery
      query={query}
      render={data => (
        <Root {...props}>
          <Container>
            <Main theme={theme}>
              <Logo />
              <Copy variant="h4" color="primary">
                ビジネスの成長を加速させる<br />中小企業に特化したクラウドERP
              </Copy>
              <USPList>
                <USPList_Item>
                  <USP theme={theme}>
                    <USP_Icon name="circle_yen" theme={theme} />
                    <USP_Body variant="body1" theme={theme}>
                      <strong>月額わずか5万円</strong>で導入可能
                    </USP_Body>
                  </USP>
                </USPList_Item>
                <USPList_Item>
                  <USP theme={theme}>
                    <USP_Icon name="circle_bundle" theme={theme} />
                    <USP_Body variant="body1" theme={theme}>
                      販売・購買・会計を<strong>1つに集約</strong>
                    </USP_Body>
                  </USP>
                </USPList_Item>
                <USPList_Item>
                  <USP theme={theme}>
                    <USP_Icon name="circle_analysis" theme={theme} />
                    <USP_Body variant="body1" theme={theme}>
                      <strong>2,300社</strong>の業務分析から設計
                    </USP_Body>
                  </USP>
                </USPList_Item>
              </USPList>
            </Main>
            <Visual theme={theme}>
              <ImageWrapper theme={theme}>
                <Image fluid={data.image.childImageSharp.fluid}></Image>
              </ImageWrapper>
            </Visual>
            <Foot theme={theme} />
          </Container>
        </Root>
      )}
    />
  )
}

const Root = styled(layouts.components.FullWidth)`
  position: relative;
  overflow: hidden;
  @media ${mq.and(desktop, laptop, tabletL)} {
    padding-top: 90px;
  }
  @media ${mq.and(tabletS, mobile)} {
    padding-top: 64px;
  }
`

const Container = styled(layouts.components.Container())`
`

const Main = styled.div`
  position: relative;
  z-index: 1;
  padding: 10px;
  margin-left: -10px;
  background-color: ${props => props.theme.background.base};
  max-width: 518px;
  @media ${mq.and(tabletL)} {
    max-width: 50%;
  }
  @media ${mq.and(tabletS, mobileL)} {
    margin: 0 auto;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
  @media ${mq.and(mobileM)} {
    margin: 0 auto;
    max-width: 320px;
  }
  @media ${mq.and(mobileS)} {
    padding: 0;
    margin-left: 0;
    max-width: 100%;
  }
`

const Logo = styled(LogoLarge)`
`

const Copy = styled(Typography)`
  margin-top: 48px;
  white-space: nowrap;
  font-weight: bold;
  @media ${mq.and(tabletS, mobileL)} {
    margin: 24px auto 0;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
    text-align: center;
  }
  @media ${mq.and(mobileM)} {
    margin: 16px auto 0;
    max-width: 320px;
    text-align: center;
  }
  @media ${mq.and(mobile)} {
    margin: 24px auto 0;
    text-align: center;
  }
`

const USPList = styled.ul`
  margin-top: 24px;
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 36px;
  }
`

const USPList_Item = styled.li`
  &:not(:first-of-type) {
    margin-top: 9px;
  }
`

const USP = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  height: 50px;
  border: 1px solid ${props => props.theme.line.base};
`

const USP_Icon = styled(IconFont)`
  font-size: 32px;
  color: ${props => props.theme.primary.base};
`

const USP_Body = styled(Typography)`
  margin-left: 12px;
  color: ${props => props.theme.gray.e};
  strong {
    font-weight: bold;
  }
`

const Visual = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    position: absolute;
    bottom: 0;
    left: 50%;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 48px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 220px;
      background-color: ${props => props.theme.primary.base};
      @media ${mq.and(mobile)} {
        height: 140px;
      }
      @media ${mq.and(mobileS)} {
        height: 112px;
      }
    }
  }
`

const ImageWrapper = styled(layouts.components.FullWidth)`
  @media ${mq.and(mobile)} {
    padding: 0 24px;
  }
  @media ${mq.and(mobileS)} {
    padding: 0 16px;
  }
`

const Image = styled(GatsbyImage)`
  position: relative;
  margin-left: 82px;
  max-width: 900px;
  @media ${mq.and(tabletS, mobileL)} {
    margin: 0 auto;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
  @media ${mq.and(mobileM, mobileS)} {
    margin: 0 auto;
    max-width: 320px;
  }
`

const Foot = styled(layouts.components.FullWidth)`
  margin-top: -98px;
  height: 196px;
  background-color: ${props => props.theme.primary.base};
  @media ${mq.and(tabletS, mobile)} {
    display: none;
  }
`

export default ThemeConsumer(ConceptKV)

export const query = graphql`
  query ConceptKVQuery {
    image: file(relativePath: { eq: "main-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
