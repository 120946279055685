import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const Integrate = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 140 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.5 97.5H33.4688V100H33.5V103H28.5V97.5ZM43.3438 102.5H38.4062V97.5H43.3438V102.5ZM53.2188 102.5H48.2812V97.5H53.2188V102.5ZM63.0938 102.5H58.1562V97.5H63.0938V102.5ZM72.9688 102.5H68.0312V97.5H72.9688V102.5ZM82.8438 102.5H77.9062V97.5H82.8438V102.5ZM92.7188 102.5H87.7812V97.5H92.7188V102.5ZM102.594 102.5H97.6562V97.5H102.594V102.5ZM107.531 100H107.5V103H112.5V97.5H107.531V100ZM33.5 109V112H28.5V109H33.5ZM107.5 112V109H112.5V112H107.5Z" fill="#333333"/>
      <line x1="70.5" y1="81" x2="70.5" y2="102" stroke="#333333" strokeWidth="5" strokeMiterlimit="16" strokeDasharray="5 5"/>
      <rect x="3.5" y="114.5" width="55" height="30" rx="2.5" fill="white" stroke="#828282" strokeWidth="5" strokeLinejoin="bevel"/>
      <rect x="80.5" y="114.5" width="55" height="30" rx="2.5" fill="white" stroke="#828282" strokeWidth="5" strokeLinejoin="bevel"/>
      <mask id="path-5-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M92.6064 16.825C94.6472 16.2867 96.7901 16 99 16C111.647 16 122.101 25.3913 123.768 37.5808C133.25 41.1325 140 50.278 140 61C140 74.8071 128.807 86 115 86H25C11.1929 86 0 74.8071 0 61C0 48.851 8.666 38.726 20.1537 36.4694C21.941 16.0316 39.0983 0 60 0C73.4534 0 85.3555 6.64168 92.6064 16.825Z"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M92.6064 16.825C94.6472 16.2867 96.7901 16 99 16C111.647 16 122.101 25.3913 123.768 37.5808C133.25 41.1325 140 50.278 140 61C140 74.8071 128.807 86 115 86H25C11.1929 86 0 74.8071 0 61C0 48.851 8.666 38.726 20.1537 36.4694C21.941 16.0316 39.0983 0 60 0C73.4534 0 85.3555 6.64168 92.6064 16.825Z" fill="white"/>
      <path d="M92.6064 16.825L88.5334 19.7251L90.5387 22.5414L93.8816 21.6597L92.6064 16.825ZM123.768 37.5808L118.814 38.2583L119.219 41.216L122.014 42.2631L123.768 37.5808ZM20.1537 36.4694L21.1174 41.3756L24.8071 40.6509L25.1347 36.905L20.1537 36.4694ZM93.8816 21.6597C95.5098 21.2302 97.2243 21 99 21V11C96.3559 11 93.7845 11.3433 91.3311 11.9904L93.8816 21.6597ZM99 21C109.114 21 117.481 28.5116 118.814 38.2583L128.722 36.9033C126.721 22.2711 114.181 11 99 11V21ZM122.014 42.2631C129.608 45.1074 135 52.4303 135 61H145C145 48.1257 136.893 37.1576 125.522 32.8985L122.014 42.2631ZM135 61C135 72.0457 126.046 81 115 81V91C131.569 91 145 77.5685 145 61H135ZM115 81H25V91H115V81ZM25 81C13.9543 81 5 72.0457 5 61H-5C-5 77.5685 8.43145 91 25 91V81ZM5 61C5 51.2864 11.9297 43.1804 21.1174 41.3756L19.1899 31.5632C5.40225 34.2716 -5 46.4155 -5 61H5ZM25.1347 36.905C26.6981 19.0272 41.7137 5 60 5V-5C36.483 -5 17.1839 13.0361 15.1727 36.0338L25.1347 36.905ZM60 5C71.7677 5 82.1803 10.8027 88.5334 19.7251L96.6794 13.9249C88.5307 2.48065 75.139 -5 60 -5V5Z" fill="#47B347" mask="url(#path-5-inside-1)"/>
      <rect x="13" y="126" width="35" height="7" fill="#333333"/>
      <rect x="90" y="126" width="35" height="7" fill="#333333"/>
      <path d="M53.2085 43.2865H63.5646V45.8401H56.2159V49.1872H62.6475V51.7109H56.2218V57.8144H53.2085V43.2865Z" fill="#47B247"/>
      <path d="M73.8255 43.2686H76.9758L72.0211 57.8204H69.1626L64.2495 43.2686H67.4951L70.6514 54.3122L73.8255 43.2686Z" fill="#47B247"/>
      <path d="M87.731 51.52C86.8794 52.23 85.6646 52.588 84.0864 52.588H81.0612V57.8204H78.0479V43.2686H84.283C85.7182 43.2686 86.8675 43.6445 87.7191 44.3903C88.5707 45.142 88.9995 46.2995 88.9995 47.8746C89.0055 49.5989 88.5767 50.81 87.731 51.52ZM85.4145 46.2756C85.0333 45.9535 84.4914 45.7924 83.8006 45.7924H81.0671V50.0702H83.8006C84.4914 50.0702 85.0274 49.8972 85.4145 49.5452C85.8015 49.1932 85.9921 48.6443 85.9921 47.8865C85.9921 47.1348 85.7956 46.5978 85.4145 46.2756Z" fill="#47B247"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(140, 147)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default Integrate
