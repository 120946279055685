import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import PageBase from '@/components/common/Page'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { layouts } from '@/utils/preset'
import Breadcrumb from '@/components/app/Breadcrumb.container'
import ConceptKV from '@/components/modules/Concept/KV'
import TopicList from '@/components/modules/Topic/List'
import AboutLead from '@/components/modules/About/Lead'
import ReasonList from '@/components/modules/Concept/ReasonList'
import Effect from '@/components/modules/Concept/Effect'
import FeatureLead from '@/components/modules/Feature/Lead'
import NewsLead from '@/components/modules/News/Lead'
import ConversionLead from '@/components/modules/Conversion/Lead'

const IndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      return (
        <Root>
          <SEO ogType="website" location={props.location}/>
          <LD json={LDEntity.WebSite(siteMeta)}/>
          <Breadcrumb path={props.location.pathname} />
          <Head>
          </Head>
          <Main>
            <ConceptKV />
            <TopicList />
            <AboutLead />
            <Border theme={props.theme} />
            <ReasonList />
            <Border theme={props.theme} />
            <Effect />
            <Border theme={props.theme} />
            <FeatureLead />
            <Border theme={props.theme} />
            <NewsLead />
            <ConversionLead />
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div`
`

const Head = styled.div``

const Main = styled.div``

const Border = styled(layouts.components.FullWidth)`
  height: 1px;
  background-color: ${props => props.theme.line.base};
`

export default PageBase(ThemeConsumer(IndexPage))

const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
        company {
          name
          logo
          siteUrl
        }
      }
    }
  }
`
