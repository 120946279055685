import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
import Typography from '@/components/common/Typography'
import Bg from '@/components/app/Bg'
import Effect from '@/components/svg/Effect'

const ConceptEffect = props => {
  return (
    <Root {...props}>
      <Head>
        <Title theme={props.theme}>
          社員の充実を生み出すのは<br />
          <strong>会社の責任です</strong>
        </Title>
      </Head>
      <Main>
        <Body variant="body1">
          IT活用による、業務の自動化・効率化は必要不可欠です。その結果として、社員が無駄な間接業務に時間を奪われることがなくなります。社員をより生産性の高い業務へ割り振り、プライベートの時間も大切にしてもらえます。そんな会社作りを我々は全力でサポートいたします。
        </Body>
        <Chart>
          <Chart_Content />
        </Chart>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
`

const Chart = styled(Bg)`
  margin: 64px auto 0;
  max-width: 996px;
  padding: 38px;
  @media ${mq.and(mobile)} {
    margin-top: 48px;
    margin-left: ${layouts.defs.pad.mobile * -1}px;
    margin-right: ${layouts.defs.pad.mobile * -1}px;
    padding-left: ${layouts.defs.pad.mobile}px;
    padding-right: ${layouts.defs.pad.mobile}px;
  }
`

const Chart_Content = styled(Effect)`
  width: 100%;
  max-width: 772px;
  margin: 0 auto;
`

export default ThemeConsumer(ConceptEffect)
