import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile } from '@/utils/media'
import { mq, aspectRatio, aspectRatioChild } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Bg from '@/components/app/Bg'

const AboutSolutionCard = props => {
  const { icon, body, ...other } = props
  return (
    <Root {...other}>
      <Head>
        <Icon><Icon_Content>{React.cloneElement(icon, { size: 'contain' })}</Icon_Content></Icon>
      </Head>
      <Main>
        <Body variant="body2">{body}</Body>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Head = styled(Bg)`
  width: 100%;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mq.and(mobile)} {
    padding: 24px;
  }
`

const Icon = styled.div`
  ${aspectRatio(1, 1)};
  width: 100%;
`

const Icon_Content = styled.div`
  ${aspectRatioChild()};

  > * {
    height: 100%;
  }
`

const Main = styled.div`
  margin-top: 24px;
  @media ${mq.and(mobile)} {
    margin-top: 8px;
  }
  width: 100%;
`

const Body = styled(Typography)`
  width: 100%;
`

export default ThemeConsumer(AboutSolutionCard)
