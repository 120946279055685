import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
import NewsList from './List'

const NewsLead = props => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const posts = data.allMarkdownRemark.edges.map(edge => {
          const postDate = new Date(edge.node.frontmatter.date)
          return {
            ...edge.node.frontmatter,
            date: {
              fullYear: `${postDate.getFullYear()}`,
              year: `${postDate.getFullYear()}`.substring(2, 4),
              month: `${postDate.getMonth() + 1}`,
              date: `${postDate.getDate()}`
            },
            html: edge.node.html,
            excerpt: edge.node.excerpt,
            slug: edge.node.fields.slug
          }
        })
        return (
          <Root {...props}>
            <Head>
              <Title>
                NEWS
              </Title>
            </Head>
            <Main>
              <NewsList posts={posts} />
            </Main>
          </Root>
        )
      }}
    />
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

export default ThemeConsumer(NewsLead)

export const query = graphql`
  query NewsLeadQuery {
    allMarkdownRemark (
      filter: { frontmatter: { postType: { eq: "news" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          html
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            title
            date
            tags
          }
        }
      }
    }
  }
`
