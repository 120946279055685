import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'

const TopicList = props => {
  const { ...others } = props
  return (
    <StaticQuery
      query={query}
      render={data => {
        const postTitle = data.topics.edges[0].node.post
        const edge = data.news.edges.find((edge) => {
          return edge.node.frontmatter.title === postTitle
        })
        if (!edge) {
          return
        }
        const post = edge.node
        return (
          <Root {...others}>
            <Container>
              <Title to={post.fields.slug}>
                <Title_Text variant="caption1">{postTitle}</Title_Text>
              </Title>
              <Arrow>
                <Arrow_Svg width="8" height="10" viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" />
                </Arrow_Svg>
              </Arrow>
            </Container>
          </Root>
        )
      }}
    />
  )
}

export default ThemeConsumer(TopicList)

const Root = styled(layouts.components.FullWidth)`
  background-color: ${props => props.theme.gray.e};
  color: ${props => props.theme.gray.contrast.c};
`

const Container = styled(layouts.components.Container({
  base: {
    top: 13,
    bottom: 13
  },
  tablet: {
    top: 13,
    bottom: 13
  },
  mobile: {
    top: 13,
    bottom: 13
  },
  mobileS: {
    top: 13,
    bottom: 13
  }
}))`
  display: flex;
  justify-content: center;
  align-items: baseline;
  @media ${mq.and(mobile)} {
    justify-content: space-between;
  }
`

const Title = styled(Link)`
  padding-left: 20px;
  text-align: center;
  @media ${mq.and(mobile)} {
    padding-left: 0;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
  }
`

const Title_Text = styled(Typography)`
  line-height: 1.5;
`

const Arrow = styled.span`
  margin-left: 12px;
  flex-grow: 0;
  flex-shrink: 0;
`

const Arrow_Svg = styled.svg`
  fill: currentColor;
`

const query = graphql`
  query TopicListQuery {
    topics: allTopicYaml {
      edges {
        node {
          post
        }
      }
    }
    news: allMarkdownRemark (
      filter: { frontmatter: { postType: { eq: "news" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
