import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
import Reason1 from '@/components/svg/Reason1'
import Reason2 from '@/components/svg/Reason2'
import Reason3 from '@/components/svg/Reason3'
import Section from './ReasonSection'

const ConceptReasonList = props => (
  <Root {...props}>
    <Head>
      <Title theme={props.theme}>FVPが<strong>選ばれる理由</strong></Title>
    </Head>
    <Main>
      <SectionList>
        <SectionList_Item>
          <Section
            number={1}
            title={(
              <SectionTitle>
                2,300社の業務分析から設計、<br />
                高品質で低価格のERPを実現。
              </SectionTitle>
            )}
            body={(
              <Fragment>
                FVPは、企業経営・業務分析の専門家である「中小企業診断士」が、2,300社の業務分析結果にもとづき設計したクラウドERPです。
                大企業向けに作られた従来のERPから機能を選定し、中小企業向けに軽量化されているため、高品質でありながら低価格なERPを実現しています。
              </Fragment>
            )}
            chart={(
              <Reason1 />
            )}
          />
        </SectionList_Item>
        <SectionList_Item>
          <Section
            number={2}
            title={(
              <SectionTitle>
                ERPの提供にとどまらない。<br />
                あらゆる課題の解決をサポート。
              </SectionTitle>
            )}
            body={(
              <Fragment>
                ERPは導入するだけでは、ビジネス上の本質的な課題を見つけ、解決することはできません。
                FVPは、中小企業診断士が、導入前の分析調査・費用対効果の算出や、導入後のトレーニングやコンサルティングまで、サービス導入効果を最大化し、ビジネスを成功に導くための幅広いサポートも用意しています。
              </Fragment>
            )}
            chart={(
              <Reason2 />
            )}
          />
        </SectionList_Item>
        <SectionList_Item>
          <Section
            number={3}
            title={(
              <SectionTitle>
                独自の要望にも対応できる<br />
                柔軟な拡張開発。
              </SectionTitle>
            )}
            body={(
              <Fragment>
                クラウド型のシステムは、低コストで導入できるものの、自由なカスタマイズが難しく柔軟性にかけるデメリットがあります。
                FVPでは、企業ごとに専用の環境を用意することで、企業で独自開発したシステムの移行や外部システムとの連携など、企業の業務に合わせた柔軟な拡張開発を可能にしています。
              </Fragment>
            )}
            chart={(
              <Reason3 />
            )}
          />
        </SectionList_Item>
      </SectionList>
    </Main>
  </Root>
)

export default ThemeConsumer(ConceptReasonList)

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  white-space: nowrap;
  strong {
    color: ${props => props.theme.primary.base};
  }
`

const Main = styled.div`
  margin: 100px auto 0;
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 48px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const SectionList = styled.div`
`

const SectionList_Item = styled.div`
  &:not(:nth-of-type(1)) {
    margin-top: 92px;
    @media ${mq.and(mobile)} {
      margin-top: 64px;
    }
  }
`

const SectionTitle = styled.span``
