import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq, aspectRatio, aspectRatioChild } from '@/utils/helpers'
import { layouts } from '@/utils/preset'
import Typography from '@/components/common/Typography'
import Bg from '@/components/app/Bg'

const ConceptReasonSection = props => {
  const { number, title, body, chart, ...others } = props
  const even = number % 2 === 0
  return (
    <Root {...others} even={even}>
      <Left>
        <Title>
          <Title_Number variant="h1" lang="en" component="span" color="primary">{number}</Title_Number>
          <Title_Text variant="h4" component="span" theme={props.theme} color="gray.e">{title}</Title_Text>
        </Title>
        <Body variant="body2">{body}</Body>
      </Left>
      <Right>
        <Chart even={even}>
          <Chart_Content>{chart}</Chart_Content>
        </Chart>
      </Right>
    </Root>
  )
}

export default ThemeConsumer(ConceptReasonSection)

const Root = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
    flex-direction: ${props => props.even ? 'row-reverse' : 'row'};
  }
`

const Left = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  @media ${mq.and(tabletS, mobile)} {
    margin: 0 auto;
    justify-content: center;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
    text-align: center;
  }
`

const Title_Number = styled(Typography)`
  margin-right: 8px;
  @media ${mq.and(tabletS, mobile)} {
    display: none;
  }
`

const Title_Text = styled(Typography)`
  position: relative;
  padding-left: 24px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 0;
    bottom: 6px;
    width: 5px;
    background-color: ${props => props.theme.text.base};
  }
  @media ${mq.and(tabletS, mobile)} {
    padding-left: 0;
    &::before {
      display: none;
    }
  }
`
const Body = styled(Typography)`
  margin: 32px auto 0;
  strong {
    font-weight: bold;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin: 24px auto 0;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
`

const Right = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
`

const Chart = styled(Bg)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${mq.and(desktop, laptop, tabletL)} {
    max-width: 490px;
    margin-left: ${props => props.even ? '0' : 'auto'};
    margin-right: ${props => props.even ? 'auto' : '0'};
  }
  @media ${mq.and(tabletS, mobile)} {
    margin: 32px auto 0;
    max-width: 490px;
  }
  @media ${mq.and(mobile)} {
    margin-left: ${layouts.defs.pad.mobile * -1}px;
    margin-right: ${layouts.defs.pad.mobile * -1}px;
    padding: 38px ${layouts.defs.pad.mobile}px;
  }
  ${aspectRatio(511, 360)};
`

const Chart_Content = styled.div`
  ${aspectRatioChild()};
  @media ${mq.and(desktop, laptop, tabletL)} {
    padding: 30px;
  }
  @media ${mq.and(mobile)} {
    padding: 38px ${layouts.defs.pad.mobile}px;
  }
  > * {
    width: 100%;
    height: 100%;
  }
`
